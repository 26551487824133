import {getMessage, getOrderFields} from "../selectors";
import fetch from "~/utils/fetch";
import { CartItem, CostValues } from "~/cart/types";
import { TFunction } from "next-i18next";
import { Field } from "~/tenant/types";
import { PaymentMethods } from "@pency/api/orders/types";

export default {
  create: (slug, {items, orderId, fields, preference = null, t, mapPrices}) =>
    fetch("POST", "/api/order", {
      slug,
      order: {
        id: orderId,
        message: getMessage(items, orderId, t, mapPrices, fields, preference),
      },
    })
      .then(() => console.info("Order sent correctly"))
      .catch(() => console.warn("Order failed")),
  saveOrder: async (items: CartItem[], orderId = '', tenantId = '', createdAt = null, currency: string, mapPrices: CostValues, fields: Field[], paymentMethod: PaymentMethods, t: TFunction, tier: string) => {
    const { cost, delivery, financial, opt } = mapPrices;
    const orderFields = fields ? getOrderFields(fields, t) : [];
    const orderState = tier === "commercial" || tier === "PLAN_PREMIUM" || tier === "PLAN_TRIAL" ? "PENDING" : "FREE_TIER";
    return fetch("POST", "/api/saveOrder", {
      order: {
        _id: orderId,
        tenantId,
        items,
        totalPrice: cost,
        createdAt,
        currency,
        fields: orderFields,
        paymentMethod,
        state: orderState,
        priceVar: { 
          delivery: delivery, 
          financial: financial, 
          opt: opt 
        }
      },
    },
    {
      Authorization: window.localStorage.getItem("token"),
    })
    .then(() => console.info("Order save correctly"))
    .catch(() => {
      console.warn("Saving order failed");
    });
  }
};
