import React from "react";
import {useForm, Controller} from "react-hook-form";
import {Stack} from "@chakra-ui/core";
import {produce} from "immer";
import FormControl from "@pency/components/form/FormControl";

import FieldInput, {validator as FieldInputValidator} from "../inputs/Field";

import {Field} from "~/tenant/types";

interface Props {
  defaultValues?: Field[];
  onSubmit: (values: Field[], formValues, total, getTotalWithDelivery) => void;
  total: number;
  getTotalWithDelivery: (formValues: any) => number;
  children: (options: {
    form: JSX.Element;
    formValues: FormData;
    isLoading: boolean;
    submit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  }) => JSX.Element;
}

interface FormData {
  fields: string[];
}

const FieldsForm: React.FC<Props> = ({defaultValues, children, getTotalWithDelivery, onSubmit, total}) => {
  const {handleSubmit: submit, watch, formState, control, errors} = useForm<FormData>({
    defaultValues: {fields: defaultValues.map((field) => field.value)},
  });
  const formValues = watch()

  function handleSubmit(values: FormData) {
    return onSubmit(
      produce(defaultValues, (fields) => {
        fields.forEach((field, index) => {
          field.value = values.fields?.[index];
        });
      }),
      formValues, 
      total,
      getTotalWithDelivery
    );
  }

  return children({
    isLoading: formState.isSubmitting,
    submit: submit(handleSubmit),
    formValues: formValues,
    form: (
      <form onSubmit={submit(handleSubmit)}>
        <Stack shouldWrapChildren spacing={8}>
          {defaultValues.map((field, index) => {
            const error = errors.fields?.[index]?.message;

            if (!field.active) return null

            if (field.type === 'select' && field.id.includes("custom_field")) return  null

            if (field.type === "note") return <FieldInput field={field} />;

            return (
              <FormControl
                key={field.id}
                error={error}
                isRequired={field.required}
                name={`fields[${index}]`}
              >
                <Controller
                  as={FieldInput}
                  control={control}
                  field={field}
                  name={`fields[${index}]`}
                  rules={{
                    validate: FieldInputValidator(field),
                  }}
                />
              </FormControl>
            );
          })}
        </Stack>
      </form>
    ),
  });
};

export default FieldsForm;
