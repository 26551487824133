import React from "react";
import {Stack, Box, Flex, useDisclosure, Text} from "@chakra-ui/core";
import {useRouter, NextRouter} from "next/router";
import Link from "@pency/components/controls/Link";
import Content from "@pency/components/structure/Content";
import Well from "@pency/components/feedback/Well";

import ProductCard from "../../components/ProductCard";
import {useFilteredProducts} from "../../hooks";
import ProductsCarousel from "../../components/ProductsCarousel";

import Onboarding from "./Onboarding";

import {parseSlug} from "~/utils/url";
import {useCart} from "~/cart/hooks";
import {groupBy} from "~/selectors/group";
import CartSummaryDrawer from "~/cart/components/CartSummaryDrawer";
import {filterBy} from "~/selectors/filter";
import {useTenant} from "~/tenant/hooks";
import {useTranslation} from "~/i18n/hooks";
import TenantHeader from "~/tenant/components/TenantHeader";
import SummaryButton from "~/cart/components/SummaryButton";
import CartItemDrawer from "~/cart/components/CartItemDrawer";
import {Product, Variant} from "~/product/types";
import CategoriesList from "~/product/components/CategoriesList";
import {CartItem} from "~/cart/types";
import { useExperiment } from "~/optimize/hooks";
import { SHARE_ADD_BUTTONS } from "~/optimize/activeExperiments";
import visitsApi from "~/visits/api/client";
import { getVariableFromLS, setVariableToLS } from "~/utils/ls";
import { Order } from "~/order/types";
import CardOrderDrawer from "~/cart/components/CartItemDrawer/CardOrderDrawer";
import NextImage from 'next/image'

interface Router extends NextRouter {
  query: {
    slug?: string[];
  };
}

interface Props {
  order?: Order
  pageId?: string
}

const ProductsScreen: React.FC<Props> = ({order, pageId}) => {
  const {query, push} = useRouter() as Router;

  const product = parseSlug(query.slug);
  const displayOrder = order?._id === parseSlug(query.slug)

  const {add, increase, increaseMany, decrease, items, checkout} = useCart();
  const t = useTranslation();
  const {isOpen: isCartOpen, onOpen: openCart, onClose: closeCart} = useDisclosure();
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const {products, element, ...filters} = useFilteredProducts(
    (product) => product.type !== "hidden",
  );
  const {highlight, fields, layout, country, checkout: methods, flow, ...tenant} = useTenant();
  const selected = React.useMemo(
    () => (product ? products.find((_product) => _product.id === product) : null),
    [products, product],
  );
  const featuredProducts = React.useMemo(() => filterBy(products, {featured: true}), [products]);
  const productsByCategory = React.useMemo(() => groupBy(products, (product) => product.category), [
    products,
  ]);
  const OPTvariant = useExperiment(SHARE_ADD_BUTTONS);

  function changeRoute(path = "") {
    push(
      {
        pathname: `/[...slug]`,
      },
      {
        pathname: `/${tenant.slug}/${path}`,
      },
      {shallow: true},
    );
  }

  function checkItemsOnCart(items: CartItem[], product: Product, options:Variant[]) {
     return items.find((item:CartItem) => {
        const isSameProduct = item.product.id === product.id

        if(isSameProduct && !options) return item

        if(isSameProduct && JSON.stringify(item.variants) === JSON.stringify(options)) return item
      })
    }

  function handleAdd(product: Product, options: Variant[], count: number, note: string) {
    const isProductInCart = checkItemsOnCart(items, product, options)

    isProductInCart ? increaseMany(isProductInCart.id, count) : add(product, options, count, note);

    changeRoute();
  }

  function handleOpenCart() {
    openCart();
  }

  function handleCloseSelected() {
    changeRoute();
  }

  function handleSelect(product: Product) {
    changeRoute(product.slug);
    setTimeout(() => {
      setOpenDrawer(true)
    },100)
  }

  function visitCounter() {
    const LSHour = getVariableFromLS('visitCountedAt');
    const thisHour = (new Date()).getHours();
    if (LSHour !== thisHour) {
      visitsApi.visitsCount(tenant.id);
      setVariableToLS('visitCountedAt', thisHour);
    }
  } 

  React.useEffect(() => {
    visitCounter();
    product && setTimeout(() => {
      setOpenDrawer(true)
    },100)
  },[])

  return (
    <>
      <Flex direction="column" height="100%">
        <Flex as="main" backgroundColor="white" direction="column" flex={1} height="100%">
          <Content height="100%" paddingX={{base: 0, sm: 4}}>
            <TenantHeader data-test-id="header" marginBottom={4} tenant={tenant} />
            <Box flex={1}>
              {highlight && (
                <Box
                  backgroundColor="primary.50"
                  color="primary.500"
                  fontSize={{base: "sm", sm: "md"}}
                  fontWeight="500"
                  marginTop={4}
                  paddingX={4}
                  paddingY={3}
                  roundedTop={{base: 0, sm: "lg"}}
                  textAlign={{base: "left", sm: "center"}}
                >
                  {highlight}
                </Box>
              )}
              <Box
                backgroundColor="gray.50"
                data-test-id="filters"
                marginBottom={{base: 5, sm: 10}}
                paddingX={4}
                paddingY={1}
                position="sticky"
                roundedBottom="lg"
                roundedTop={highlight ? "none" : "lg"}
                top={0}
                zIndex={3}
              >
                {element}
              </Box>
              <Box marginBottom={4} paddingX={0}>
                <Stack margin="auto" spacing={5} width="100%">
                  {Boolean(products.length) ? (
                    <Stack spacing={{base: 5, sm: 10}} width="100%">
                      {Boolean(featuredProducts.length) && (
                        <ProductsCarousel
                          paddingX={{base: 4, sm: 0}}
                          title={t("common.featured")}
                          zIndex={0}
                        >
                          {featuredProducts.map((product) => (
                            <ProductCard
                              key={product.id}
                              isRaised
                              layout="portrait"
                              minWidth={280}
                              product={product}
                              onClick={() => handleSelect(product)}
                            />
                          ))}
                        </ProductsCarousel>
                      )}
                      <CategoriesList
                        categories={productsByCategory}
                        flow={flow}
                        layout={layout}
                        selected={filters.state.category}
                        onCategoryClick={filters.actions.setCategory}
                        onProductClick={handleSelect}
                      />
                    </Stack>
                  ) : (
                    <Well data-test-id="empty">{t("products.empty")}</Well>
                  )}
                </Stack>
              </Box>
            </Box>
          </Content>
        </Flex>
      </Flex>
      {Boolean(items.length) && (
        <Flex
          as="nav"
          bottom={0}
          justifyContent="center"
          margin={{base: 0, sm: "auto"}}
          paddingBottom={4}
          paddingX={4}
          position="sticky"
          width="100%"
          zIndex={2}
        >
          <Box
            display="block"
            margin={{base: 0, sm: "auto"}}
            minWidth={{base: "100%", sm: 64}}
            rounded={4}
            width={{base: "100%", sm: "auto"}}
          >
            <SummaryButton items={items} onClick={handleOpenCart}>
              {t("products.review")}
            </SummaryButton>
          </Box>
        </Flex>
      )}
      <Content>
        <Flex
          alignItems={{base: "center", sm: "flex-end"}}
          direction={{base: "column", sm: "row"}}
          justifyContent="flex-end"
          padding={4}
        >
          <Link href="/">
            <Stack isInline alignItems="center" spacing={1}>
              <Text fontSize="sm">{t("footer.createdWith")}</Text>
              <NextImage 
                priority
                height='14x' 
                width='65px' 
                alt='Peny Logo' 
                src='/assets/logo/pency-logo.png' 
              />
            </Stack>
          </Link>
        </Flex>
      </Content>
      {isCartOpen && (
        <CartSummaryDrawer
          fields={fields}
          hasCheckout={Boolean(methods?.length)}
          items={items}
          onCheckout={checkout}
          onClose={closeCart}
          onDecrease={decrease}
          onIncrease={increase}
          isOrderScreen={order && true}
        />
      )}
      {Boolean(selected) && (
        <CartItemDrawer 
          product={selected} 
          isOpen={openDrawer} 
          setIsOpen={setOpenDrawer}
          onClose={handleCloseSelected} 
          onSubmit={handleAdd} 
          OPTvariant={OPTvariant} />
      )}
      {Boolean(displayOrder) && (
        <CardOrderDrawer
          isOpen={openDrawer}
          onClose={handleCloseSelected} 
          order={order}
          fields={fields}
          items={items}
          pageId={pageId}
        />
      )}
      <Onboarding />
    </>
  );
};

export default ProductsScreen;
