import React from "react";
import shortid from "shortid";
import produce from "immer";

import {Product, Variant, ProductStock} from "../product/types";

import {CartItem, Context, State, Actions, Cart, CheckoutResponse, CostValues} from "./types";
import {getOrderId, getTotal} from "./selectors";
import api from "./api";

import ordersApi from "~/order/api/client";
import {Field} from "~/tenant/types";
import {useAnalytics} from "~/analytics/hooks";
import {useTenant} from "~/tenant/hooks";
import paymentApi from "~/payment/api/client";
import date from "~/utils/date";
import { CURRENCIES } from "@pency/constants/catalogs/i18n";
import { useTranslation } from "~/i18n/hooks";
import { getPencyFee } from "./utils";
import { getMessage } from "~/order/selectors";
import { PaymentMethods } from "@pency/api/orders/types";
import { paymentStatusConstants } from "@pency/api/orders/controllers/patch/types";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const CartContext = React.createContext({} as Context);

const CartProvider = ({children}: Props) => {
  const log = useAnalytics();
  const tenant = useTenant()
  const {phone, slug, flags, mercadopago, country, id, tier, createdAt} = useTenant();
  const [cart, setCart] = React.useState<Cart>({});
  const items = React.useMemo(() => [].concat(...Object.values(cart)), [cart]);
  const t = useTranslation()

  function add(product: Product, variants: Variant[], count: number = 1, note: string = "") {
    log.addToCart(product);

    return setCart(
      produce((cart) => {
        const id = shortid.generate();

        cart[id] = {
          id,
          variants,
          count,
          product,
          note,
        };
      }),
    );
  }

  function remove(id: CartItem["id"]) {
    if (!cart[id]) return;

    log.removeFromCart(cart[id].product);

    return setCart(
      produce((cart) => {
        delete cart[id];
      }),
    );
  }

  function increase(id: CartItem["id"]) {
    if (!cart[id]) return;

    return setCart(
      produce((cart) => {
        cart[id].count++;
      }),
    );
  }

  function increaseMany(id: CartItem["id"], count:number) {
    if (!cart[id]) return;

    return setCart(
      produce((cart) => {
        cart[id].count += count;
      }),
    );
  }

  function decrease(id: CartItem["id"]) {
    if (!cart[id]) return;

    if (cart[id].count === 1) {
      return remove(id);
    }

    return setCart(
      produce((cart) => {
        cart[id].count--;
      }),
    );
  }

  function handleOrder(orderPrices?: CostValues) {
    // We generate an order id
    const orderId = getOrderId(slug);
    // Get total price
    const total = orderPrices ? orderPrices.cost : getTotal(items)
    // Generate order before saving it
    const order = {
      _id: orderId,
      tenantId: id,
      items,
      totalPrice: total,
      currency: CURRENCIES[country],
      state: 'PENDING',
      priceVar: { 
        delivery: orderPrices?.delivery ?? 0, 
        financial: orderPrices?.financial ?? 0, 
        opt: orderPrices?.opt ?? 'message.noCost' 
      }
    }

    return order
  }

  const validatePhone = (phone: string) => {
    if (createdAt > 1659996114201 && createdAt < 1662692400000) {
      if (phone.startsWith('+')) {
        return phone
      } else {
        if (country === "AR") {
          if (phone.startsWith('54')){
            return phone
          }
          return `+54${phone}`
        }
        if (country === "CO") {
          if (phone.startsWith('57')){
            return phone
          }
          return `+57${phone}`
        }
        if (country === "MX") {
          if (phone.startsWith('52')){
            return phone
          }
          return `52${phone}`
        }
        if (country === "GT") {
          if (phone.startsWith('502')) {
            return phone
          }
          return `502${phone}`
        }
        if (country === "BO") {
          if (phone.startsWith('591')) {
            return phone
          }
          return `591${phone}`
        }
        if (country === "BR") {
          if (phone.startsWith('55')) {
            return phone
          }
          return `55${phone}`
        }
        if (country === "CL") {
          if (phone.startsWith('56')) {
            return phone
          }
          return `56${phone}`
        }
        if (country === "PE") {
          if (phone.startsWith('51')) {
            return phone
          }
          return `51${phone}`
        }
        if (country === "CR") {
          if (phone.startsWith('506')) {
            return phone
          }
          return `506${phone}`
        }
        if (country === "CU") {
          if (phone.startsWith('53')) {
            return phone
          }
          return `53${phone}`
        }
        if (country === "DO") {
          if (phone.startsWith('1809')) {
            return phone
          }
          return `1809${phone}`
        }
        if (country === "EC") {
          if (phone.startsWith('593')) {
            return phone
          }
          return `593${phone}`
        }
        if (country === "ES") {
          if (phone.startsWith('34')) {
            return phone
          }
          return `34${phone}`
        }
        // Panama
        if (country === "PA") {
          if (phone.startsWith('507')) {
            return phone
          }
          return `507${phone}`
        }
        if (country === "PY") {
          if (phone.startsWith('595')) {
            return phone
          }
          return `595${phone}`
        }
        if (country === "SV") {
          if (phone.startsWith('503')) {
            return phone
          }
          return `503${phone}`
        }
        if (country === "US") {
          if (phone.startsWith('1')) {
            return phone
          }
          return `1${phone}`
        }
        // Uruguay
        if (country === "UY") {
          if (phone.startsWith('598')) {
            return phone
          }
          return `598${phone}`
        }
        // Venezuela
        if (country === "VE") {
          if (phone.startsWith('58')) {
            return phone
          }
          return `58${phone}`
        }
        return phone
      }

    }
    return phone
  }

  async function checkout(fields?: Field[], orderPrices?: CostValues, paymentMethod: PaymentMethods = PaymentMethods.other): Promise<CheckoutResponse> {   
    // We generate an order id
    const orderId = getOrderId(slug);

    const validPhone = validatePhone(phone)

    // Store preference
    let preference = null;

    const total = orderPrices ? orderPrices.cost : getTotal(items)
    // Log to analytics
    log.checkout(total);
    // We store order prices to use when neccesary
    const mapPrices: CostValues = {
      cost: orderPrices ? orderPrices.cost : total, 
      delivery: orderPrices ? orderPrices.delivery : 0, 
      financial: orderPrices ? orderPrices.financial : 0,
      opt: orderPrices ? orderPrices.opt : 'message.noCost'
    }
    // We create preference by paying with Mercado Pago
    if (mercadopago && paymentMethod !== PaymentMethods.other) {
      try {
        // Add Pency fee depending of tier
        const priceVar = {
          ...mapPrices,
          pencyFee: getPencyFee(tier, mapPrices.cost)
        }
        const applyFee = tenant.flags.includes("termsAndConditions")
        // Create a preference for this items
        preference = await paymentApi.create(slug, items, orderId, priceVar, country, applyFee, tier);
      } catch (e) {
        // If we had an error log it to the console
        console.warn("Error generando preferencia de MercadoPago: ", e);
      }
    }

    //We check if the current products have a valid stock for the current order.
    const updateStockProducts = getProductStockFromOrderItems();
    const stockCheckResponse = await checkStock();

    //If all the stocks are ok then we proceed.
    if (stockCheckResponse.isValidationOk) {
      //We saved the order in DB
      await ordersApi.saveOrder(items, orderId, id, date.now, CURRENCIES[country], mapPrices, fields, paymentMethod, t, tier);
      //Then we send the order to the Pency Ticket App
      // If a webhook is configured
      if (flags.includes("orders")) {
        try {
          // Do a post to it
          ordersApi.create(slug, {items, orderId, t, mapPrices, fields, preference});
        } catch (e) {
          // If we had an error log it to the console
          console.log("Hook was not dispatched: ", e);
        }
      }
      // Then we update the stock and redirect to whatsapp
      await api.updateStock(updateStockProducts).then(() => {
        // Get URL to handle redirect response
        const redirect: string = api.checkout(
          {
            phone: validPhone, 
            items, 
            orderId, 
            t, 
            mapPrices, 
            fields, 
            state: paymentStatusConstants.PAYMENT_PENDING.label,
            preference
          }
        );
        // Get order details message to create order page
        const orderDetails = getMessage(
          items, 
          orderId, 
          t, 
          mapPrices, 
          fields, 
          paymentStatusConstants.PAYMENT_PENDING.label, 
          preference
        )
        // We only persist preference when payment is via checkoutPRO
        if (paymentMethod !== PaymentMethods.checkoutPro) preference = null
        // We set Url depeding of the payment method - preference(MP)/redirect(other)
        stockCheckResponse.redirectUrl = preference ? preference : redirect;
        // we set payment method to validate operation
        stockCheckResponse.paymentIndicator = paymentMethod && paymentMethod 
        // We save the order raw text to display it to the user
        stockCheckResponse.orderDetails = orderDetails
      }); 
    } else {
      const cartCopy = Object.values(cart);
      cartCopy.forEach((itemInCart) => {
        stockCheckResponse.validationData.details.forEach((orderProduct) => {
          if (itemInCart.product.id === orderProduct.id) {
            if (!cart[itemInCart.id]) return;

            if (orderProduct.currentStock === 0) {
              return null;
            } else {
              return setCart(
                produce((cart) => {
                  cart[itemInCart.id].count = orderProduct.currentStock;
                }),
              );
            }
          }
        });
      });
    }
    return stockCheckResponse;
  }

  async function checkStock(): Promise<CheckoutResponse> {
    //First we create a smaller object for the update stock process.

    const updateStockProducts = getProductStockFromOrderItems();

    //We check if the current products have a valida stock for the current order.

    let response: CheckoutResponse = {
      isValidationOk: false,
      isError: false,
      redirectUrl: "",
      orderDetails: "",
      paymentIndicator: PaymentMethods.other
    };

    await checkStockFromAPI(updateStockProducts, response);

    return response;
  }

  //Functions used into this class.
  async function checkStockFromAPI(updateStockProducts: ProductStock[], response: CheckoutResponse) {
    try {
      const returned = await api.checkStock(updateStockProducts);
      const { success, validationData } = returned;
      response.isValidationOk = success;

      if (!success) {
        response.validationData = validationData;
      }
      return response;
    } catch (error) {
      response.isError = true;
      response.error = error;
      return response;
    }
  }

  function getProductStockFromOrderItems() {
    return items.map((orderProduct) => {
      const productStock: ProductStock = {
        id: orderProduct.product.id,
        updatedAt: date.now,
        orderStock: orderProduct.count,
      };

      return productStock;
    });
  }

  const state: State = {items, cart};
  const actions: Actions = {add, remove, checkout, checkStock, increase, increaseMany, decrease, handleOrder};

  return <CartContext.Provider value={{state, actions}}>{children}</CartContext.Provider>;
};

export {CartProvider as Provider, CartContext as default};
