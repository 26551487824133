import React, {useEffect} from "react";
import {Stack, Flex, Text, Icon, List, ListItem} from "@chakra-ui/core";
import {DrawerTitle, DrawerBody, DrawerFooter} from "@pency/components/structure/Drawer";
import Button from "@pency/components/controls/Button";
import CrossIcon from "@pency/components/icons/Cross";
import Stepper from "@pency/components/inputs/Stepper";

import {CartItem} from "../../types";

import {useTranslation, usePrice} from "~/i18n/hooks";
import {getCount, getTotal, getFormattedPrice} from "~/cart/selectors";
import {getVariantsString} from "~/product/selectors";

interface Props {
  items: CartItem[];
  onDecrease: (id: CartItem["id"]) => void;
  onIncrease: (id: CartItem["id"]) => void;
  onSubmit: () => Promise<void>;
  onClose: VoidFunction;
  hasNextStep: boolean;
  hasCheckout: boolean;
  checkoutValidationMessage: string;
}

const Overview: React.FC<Props> = ({
  items,
  onIncrease,
  onDecrease,
  onSubmit,
  onClose,
  hasCheckout,
  checkoutValidationMessage,
}) => {
  const [hasValidationMessage, setHasValidationMessage] = React.useState(false);
  const t = useTranslation();
  const p = usePrice();
  const count = getCount(items);
  const total = getTotal(items);

  function handleNext() {
    onSubmit();
  }

  function handleDecrease(id: CartItem["id"]) {
    onDecrease(id);
  }

  function handleIncrease(id: CartItem["id"]) {
    onIncrease(id);
  }

  function transormValidationMessage() {
    const messageLines: string[] = checkoutValidationMessage.split("\n");
    let jsxTitle: JSX.Element[] = [<Stack isInline><Icon name="warning" color="grey.200" width="20px" height="20px" /><Text key="validationMessage" color="red">{t("products.noStockMessage")}</Text></Stack>];
    
    let jsxToFill = [];

    for (let i = 0; i < messageLines.length - 1; i++) {
      jsxToFill.push(<ListItem key={messageLines[i]} marginLeft="5px">{messageLines[i]}</ListItem>);
    }
    let jsxList = [<List styleType="disc">{jsxToFill}</List>];
    let jsxToReturn = [jsxTitle, jsxList];

    return jsxToReturn;
  }
  useEffect(() => {
    const newValidationMessage: boolean = checkoutValidationMessage && checkoutValidationMessage.length >= 0;

    if (newValidationMessage) {
      setHasValidationMessage(newValidationMessage);
    }
  }, [checkoutValidationMessage]);

  return (
    <>
      <DrawerBody>
        <CrossIcon
          background="white"
          boxShadow="md"
          cursor="pointer"
          marginTop={4}
          paddingX={4}
          paddingY={3}
          position="absolute"
          right={0}
          roundedLeft="lg"
          top={0}
          onClick={onClose}
        />
        <Stack marginTop={20} spacing={6}>
          <DrawerTitle>
            {t("cart.yourOrder")} ({count})
          </DrawerTitle>
          {hasValidationMessage && <Stack bg="#FBEEC3" padding="12px" borderRadius="10px" >{transormValidationMessage()}</Stack>}
          <Stack shouldWrapChildren spacing={6}>
            {items.map((item) => (
              <Flex key={item.id} alignItems="flex-start" justifyContent="space-between">
                <Flex alignItems="center" mr={2}>
                  <Stack spacing={0}>
                    <Text fontWeight={500} overflowWrap="break-word">
                      {item.product.title}
                    </Text>
                    {item.variants && (
                      <Text color="gray.600">{getVariantsString(item.variants)}</Text>
                    )}
                    {item.note && <Text color="gray.600">({item.note})</Text>}
                    <Stepper
                      marginTop={2}
                      value={item.count}
                      onDecrease={() => handleDecrease(item.id)}
                      onIncrease={() => handleIncrease(item.id)}
                    />
                  </Stack>
                </Flex>
                <Flex alignItems="center">
                  <Text fontWeight={500}>{getFormattedPrice(item)}</Text>
                </Flex>
              </Flex>
            ))}
          </Stack>
        </Stack>
      </DrawerBody>
      <DrawerFooter borderTopColor="gray.100" borderTopWidth={1} marginTop={2}>
        <Stack spacing={4} width="100%">
          <Flex alignItems="center" fontSize="lg" fontWeight={500} justifyContent="space-between">
            <Text>{t("cart.estimatedTotal")}</Text>
            <Text>{p(total)}</Text>
          </Flex>
          {hasCheckout && (
            <Button boxShadow="lg" size="lg" variantColor="primary" onClick={handleNext}>
              {t("cart.initOrder")}
            </Button>
          )}
        </Stack>
      </DrawerFooter>
    </>
  );
};

export default Overview;
