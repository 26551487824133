import shortId from "shortid";

import { CartItem, CostObj } from "./types";

import { ClientTenant } from "~/tenant/types";
import { getVariantsPrice } from "~/product/selectors";
import { formatPrice } from "~/i18n/selectors";

export function getTotal(items: CartItem[]): number {
  return items.reduce((total, item) => total + getPrice(item) * item.count, 0);
}

export function getPrice(item: CartItem): number {
  switch (item.product.type) {
    // Price should not be modified
    case "hidden":
    case "unavailable":
    case "ask": {
      return 0;
    }

    // Price depends only on the variants
    case "variant": {
      return getVariantsPrice(item.variants);
    }

    // Sum total and variants
    default: {
      return item.product.price + getVariantsPrice(item.variants);
    }
  }
}

export const getCost = (fields): number => {
  const [field] = fields.filter((field) => {
    if (field.id === "default_delivery_mode") return field;
  });

  return field ? field.cost : 0
};

export const optTotal = (newObj, total) => {
  const val = parseInt(newObj.amount) / 100;
  const mul = val * total;
  if (newObj.opt === "Recargo") {
    return mul.toFixed(2);
  } else {
    return mul.toFixed(2);
  }
};

export const optNominalTotal = (newObj: CostObj) => {
  if (newObj.opt === "Recargo") {
    return newObj.amount
  } else {
    return newObj.amount
  }
};

export const formatCost = (formValues): CostObj => {
  let newObj = {
    opt: "",
    amount: "",
    operation: "",
    payment: ""
  };
  Object.entries(formValues).filter((val) => {
    if (isText(val[1])) {
      if (includesFinancial(val[1]) && val[1].includes('default')) {
        const [opt, amount, operation, payment] = val[1].split("_");
        newObj = {
          opt,
          amount,
          operation,
          payment
        };
      }
    }
  });

  return newObj;
};

export const getTotalPercentage = (
  amount: string,
  opt: string,
  total: number
) => {
  const val = parseInt(amount) / 100;
  const mul = val * total;

  return opt === "Recargo" ? total + mul : total - mul;
};

export const getTotalNominal = (
  amount: string,
  opt: string,
  total: number
) => {
  const parseAmount = parseInt(amount)
  return opt === "Recargo" ? total + parseAmount : total - parseAmount;
};

export const addFinancialCost = (formValues, total) => {
  let { operation, amount, opt } = formatCost(formValues);

  const financialCost =
    operation === "nominal"
      ? getTotalNominal(amount, opt, total)
      : getTotalPercentage(amount, opt, total);

  return {financialCost, opt}
};

export const getFinancialCost = (formValues, total) => {
  let newObj = formatCost(formValues);

  const cost =
    newObj.operation === "percentage"
      ? optTotal(newObj, total)
      : optNominalTotal(newObj);

  return cost === '' ? 0 : parseFloat(cost);
};

export const hasDelivery = (formValues, t, fields) => {
  const delivery = fields.filter((field) => {
    return field.id === "default_delivery_mode"
  })

  if (
    delivery.length > 0 && 
    !["Custo fixo", "Costo fijo", 'Fixed cost'].includes(delivery[0]?.options[1]?.selected)
  ) return undefined

  const [val] = Object.entries(formValues).filter((val) => {
    if (val[1] === t('admin.config.customFields.card.deliveryMode.shipping'))
      return true;
    return false;
  });

  return val;
};

export const includesFinancial = (val) => {
  if(val.includes("Recargo") || val.includes("Descuento")) return true
  return false
}

export const hasFinancialCost = (formValues) => {
  const [val] = Object.entries(formValues).filter((val) => {
    if (isText(val[1])) {
      if (includesFinancial(val[1]) && val[1].includes('default'))
        return val[1]
    }
  });

  return val;
};

function isText(data: any): data is string {
  return typeof data === "string";
}

export const getFinancialText = (formValues) => {
  const opt = [];
  Object.entries(formValues).filter((val) => {
    if (isText(val[1])) {
      if (val[1].includes("Recargo") || val[1].includes("Descuento"))
        opt.push(val[1].split("_")[0]);
    }
  });

  return opt[0];
};

export function getFormattedPrice(item: CartItem): string {
  switch (item.product.type) {
    // This should never be shown
    case "hidden": {
      return "No disponible";
    }

    // No stock
    case "unavailable": {
      return "Sin stock";
    }

    // Ask price
    case "ask": {
      return "A consultar";
    }

    // Get price
    default: {
      return formatPrice(getPrice(item) * item.count);
    }
  }
}

export function getCount(items: CartItem[]): number {
  return items.reduce((total, item) => total + item.count, 0);
}

export const getOrderId = (slug: ClientTenant["slug"]) => {
  // Set characters
  shortId.characters(
    "0123456789abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZÑ"
  );

  // Generate order id - we replace ñ/Ñ caracters to prevent order page error.
  return `${slug.slice(0, 3).toUpperCase()}-${shortId
    .generate()
    .slice(0, 5)
    .replace('ñ', 'n')
    .replace('Ñ', 'N')
    .toUpperCase()}`;
};

export const displayProp = (price: number) => {
  return price > 0 
}
