class ImageFormatter {
  // Complete path to imagen
  private path: string;
  // Map to handle different provider cases.
  private imageUrls = {
    default: {
      page: "/assets/fallback.jpg",
      og: "/og-image.jpg",
    },
    cloudinary: (img) => {
      const base = img.split("goncy/image/upload/")[1];
      const path = base?.split("/pency")[1];
      return `https://tap-pencyinfra-prod.s3.amazonaws.com${path}`;
    },
    pencyImages: (img) =>
      `https://tap-pencyinfra-prod.s3.amazonaws.com${
        img?.split("production.pency.images")[1]
      }`,
  };

  constructor(url: string | undefined, place: string) {
    this.path = this.getImageKey(url, place);
  }

  private getImageKey(url: string, place: string): string {
    // Old bucket and admin.pency.app url
    if (url?.includes("image/production.pency.images"))
      return this.imageUrls["pencyImages"](url);
    // Cloudinary url
    if (url?.includes("cloudinary")) {
      if (url?.includes("dbfo9ppcs")) {
        //https://res.cloudinary.com/dbfo9ppcs/image/upload/v1683601649/mkylg4jz4dfwq2pgdmnt.jpg
        const imageName = url?.split("dbfo9ppcs/image/upload/v1683601649/")[1];
        return `https://tap-pencyinfra-prod.s3.amazonaws.com/general/${imageName}`;
      }
      return url;
    }
    // Current s3 bucket
    if (url?.includes("tap-pencyinfra-prod")) return url;
    // Default case - fallback
    return this.imageUrls["default"][place];
  }

  getImageUrl(): string {
    return this.path;
  }
}

export const formatImageUrl = (img: string, og: string = "place"): string => {
  const imageFormatter = new ImageFormatter(img, og);
  return imageFormatter.getImageUrl();
};
