import React from "react";

import RadioField from "./RadioField";
import TextField from "./TextField";
import NoteField from "./NoteField";
import SelectField from "./SelectField";
import CheckField from "./CheckField";

import {Field} from "~/tenant/types";
import LongTextField from "./longTextField";

interface Props {
  field: Field;
  value?: Field["value"];
  onChange?: (field: string) => void;
}

const FieldInput: React.FC<Props> = ({field, value, onChange}) => {
  
  if (field.type === "radio") return <RadioField field={field} value={value} onChange={onChange} />;
  if (field.type === 'check') return <CheckField field={field} value={value} onChange={onChange} />;
  if (field.type === 'text') return <TextField field={field} value={value} onChange={onChange} />;
  if (field.type === 'longText') return <LongTextField field={field} value={value} onChange={onChange} />
  if (field.type === 'select') return <SelectField field={field} value={value} onChange={onChange} />;
  if (field.type === "note") return <NoteField field={field} />;

  return null;
};

export default FieldInput;
