import React from "react";
import {CURRENCIES} from "@pency/constants/catalogs/i18n";

import api from "./api";
import {useTenant} from "~/tenant/hooks";
import {Product} from "~/product/types";

interface Context {
  actions: {
    log: {
      addToCart: (product: Product) => void;
      removeFromCart: (product: Product) => void;
      viewCart: () => void;
      viewFields: () => void;
      checkout: (total: number) => void;
      share: (product: Product, method: string) => void;
      viewProduct: (product: Product) => void;
    };
  };
}

const AnalyticsContext = React.createContext({} as Context);

const AnalyticsProvider: React.FC = ({children}) => {
  const tenant = useTenant();

  function addToCart(product: Product) {
    api.track({
      action: "add_to_cart",
      category: "checkout",
      label: product.title,
    });
  }

  function removeFromCart(product: Product) {
    api.track({
      action: "remove_from_cart",
      category: "checkout",
      label: product.title,
    });
  }

  function viewCart() {
    api.track({
      action: "begin_checkout",
      category: "checkout",
      label: "Overview",
    });
  }

  function viewFields() {
    api.track({
      action: "checkout_progress",
      category: "checkout",
      label: "Fields",
    });
  }

  function checkout(total: number) {
    api.track({
      action: "purchase",
      category: "checkout",
      currency: CURRENCIES[tenant.country],
      label: tenant.slug,
      value: total
    });
  }

  function share(product: Product, method: string) {
    api.track({
      action: "share",
      category: method,
      label: product.title,
    });
  }

  function viewProduct(product: Product) {
    api.track({
      action: "view_item",
      category: "product",
      label: product?.title,
    });
  }

  React.useEffect(() => {
    // Track page view
    api.pageview(window.location.pathname + window.location.search);
  }, []);

  const actions: Context["actions"] = {
    log: {
      viewCart,
      viewFields,
      addToCart,
      removeFromCart,
      checkout,
      share,
      viewProduct,
    },
  };

  return <AnalyticsContext.Provider value={{actions}}>{children}</AnalyticsContext.Provider>;
};

export {AnalyticsProvider as Provider, AnalyticsContext as default};
