import React from "react";

import {Product} from "../../../types";

import Portrait from "./Portrait";
import Landscape from "./Landscape";

import {ClientTenant} from "~/tenant/types";

interface Props {
  isSelected: boolean;
  category: Product["category"];
  layout: ClientTenant["layout"];
  products: Product[];
  onProductClick: (product: Product) => void;
}

const Fluid: React.FC<Props> = ({layout, ...props}) => {
  if (layout === "portrait") return <Portrait {...props} />;
  if (layout === "landscape") return <Landscape {...props} />;
};

export default Fluid;
